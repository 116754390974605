













import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { UserStoreModule } from '@common-src/store/modules/user';
const version = require('../../../../package.json').version;

@Component
export default class SignComponent extends BaseComponent {
    get Version() {
        return version;
    }

    get ProjectName(): string {
        return UserStoreModule.CorpName;
    }

    get ProjectLogo(): string {
        const logo = (UserStoreModule.Logo && _.isString(UserStoreModule.Logo)) ? UserStoreModule.Logo : JTL?.CONFIG?.logo;
        return logo;
    }

    get ProjectPicture(): string {
        return UserStoreModule.LoginImage;
    }
}
